.page-link {
    color: #212529 !important;
}

.page-item {
    margin: 0 auto;
}

.disabled > .page-link {
    opacity: 0.2;
}

.active > .page-link {
    font-weight: bold;    
    border: 1px solid #888;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.25);
    border-color: #888 !important;
    background-color: #CCC !important;
}

.pagination {
    justify-content: center !important;
    margin-top: 12px !important;
}

.first-pagination-mobile {
    margin-bottom: -4px !important;
    margin-top: 20px !important;
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1024px) {
    .pagination {
        padding-left: 25% !important;
        padding-right: 25% !important;
    }}

/* Large screens ----------- */
@media only screen 
and (min-width : 1824px) {
    .pagination {
        padding-left: 35% !important;
        padding-right: 35% !important;
    }
}