.navigation-link {
    color: #263F59 !important;
    transition: 0s !important;
}

.navigation-link:hover {
    color: #F5F7FA !important;
    background-color: #263F59;
    border-radius: 8px;
    opacity: 0.4;
}

.navbar-toggler {
    border: none !important;
}