.tag-dropdown {
    list-style-type: none;
    padding: 0;
    border: 1px solid #ddd;
}

.tag-dropdown:empty {
    list-style-type: none;
    padding: 0;
    border: none;
    margin-bottom: 0px;
}